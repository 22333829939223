.Home {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.HomeHead {
  display: flex;
  align-items: center;
  margin: 0 20px;
  margin-top: 20px;
  font-weight: 700;
}

.HomeHead select {
  background-color: transparent;
  border: none;
  outline: none;
  color: #898b9a;
  font-size: 15px;
  margin-left: 10px;
}

.HomeHead button {
  height: 33px;
  padding: 0 15px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: #3762ea;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.HomeStatCon {
  display: grid;
  grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  margin: 0 20px;
  justify-content: space-between;
}

.HomeQuestCon {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 0px 2px #00000012;
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.HomeStat {
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 0px 2px #00000012;
  padding-bottom: 15px;
  margin-top: 20px;
}

.HomeStatDiv {
  display: flex;
  align-items: center;
}

.HomeStat h3 {
  margin: 0 15px;
  margin-top: 15px;
  color: #898b9a;
  font-size: 15px;
  font-weight: 500;
}

.HomeStat h2 {
  margin: 0 15px;
  margin-top: 15px;
  color: #000;
  font-size: 22px;
  font-weight: 600;
}

.HomeStat h4 {
  align-items: center;
  width: fit-content;
  margin: 0;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 15px;
  color: #2dcb72;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  padding-left: 17px;
  padding-right: 7px;
  background-color: #e0f7ea;
  position: relative;
}

.HomeStat h5 {
  align-items: center;
  width: fit-content;
  margin: 0;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 15px;
  color: #ff6c6c;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  padding-left: 17px;
  padding-right: 7px;
  background-color: #ffe9e9;
  position: relative;
}

.HomeStatIcon {
  position: absolute;
  top: 3px;
  left: 4px;
}

.HomeChart {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin: 0 20px;
  margin-top: 20px;
  justify-content: space-between;
}

.HomeChart3 {
  width: calc(100% - 40px);
  margin: 0 20px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 2px #00000012;
}

.HomeChart3 h3 {
  margin: 0 15px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
}

.HomeChart4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 2px #00000012;
  margin-bottom: 10px;
  position: relative;
}

.HomeChart4 svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  transition: 2s ease;
}

.HomeChart4 svg path:hover {
  fill: #3f9ffb;
}

.HomeChart1 {
  width: fit-content;
  display: flex;
  width: 100%;
  border-radius: 3px;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 2px #00000012;
}

.HomeChart1 h3 {
  margin: 0 15px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
}

.HomeChart2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 2px #00000012;
  margin-bottom: 10px;
}

.HomeChart2 h3 {
  margin: 0 15px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
}

.Home table {
  border-collapse: collapse;
  width: 100%;
}

.Home td {
  text-align: left;
  padding: 10px 15px;
  font-weight: 400;
}

.Home th {
  text-align: left;
  padding: 10px 15px;
  font-weight: 500;
}

.Home tr:nth-child(even) {
  border-bottom: 1px solid #00000012;
  border-top: 1px solid #00000012;
}

.Home tr td:nth-child(1) {
  width: 85%;
}

.Home td h4 {
  align-items: center;
  width: fit-content;
  margin: 0;
  color: #2dcb72;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  padding-left: 17px;
  padding-right: 7px;
  background-color: #e0f7ea;
  position: relative;
}

.Home td h5 {
  align-items: center;
  width: fit-content;
  margin: 0;
  color: #ff6c6c;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  padding-left: 17px;
  padding-right: 7px;
  background-color: #ffe9e9;
  position: relative;
}

.UserSearchCon {
  display: flex;
  align-items: center;
  height: 70px;
  background-color: #fff;
  margin: 0 20px;
  margin-top: 20px;
  padding: 0 15px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #00000012;
}

.UserSearchCon input {
  width: 200px;
  padding: 10px 13px;
  font-size: 15px;
  border: #eef0f7 1px solid;
  outline: none;
  border-radius: 3px;
}

.UserSearchCon select{
  padding: 8px 13px;
  font-size: 15px;
  outline: #eef0f7 1px solid;
  border: none;
  border-right: 13px transparent solid;
  border-radius: 3px;
  margin-right: 15px;
}

.UserSearchCon button {
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: #3762ea;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.UserDiv {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.UserDivLeft {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.UserDivRight {
  flex: 1;
  min-height: 300px;
  height: fit-content;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 0px 2px #00000012;
  padding-bottom: 15px;
  position: sticky;
  top: 25px;
}

.UserDivRight table{
  margin-top: 20px;
}

.UserDivRight thead{
  background-color: #f7f8f8;
}

.UserDivRight th{
  font-size: 14px;
  font-weight: 600;
}

.UserDivRight td{
  font-size: 13px;
}

.UserDivRightEdit {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  /* position: absolute; */
  right: 15px;
  top: 34px;
  background-color: #3762ea;
  color: #fff;
  cursor: pointer;
}

.UserDivRight h4 {
  margin: 0;
  width: 60px;
  font-size: 14px;
  font-weight: 500;
}

.UserDivRight h5 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.UserDivRightDiv {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: 0 20px;
  margin-top: 10px;
}

.UserDivRightDiv1 {
  display: flex;
  align-items: center;
  margin: 0 15px;
  margin-top: 10px;
}

.UserDivRightDiv1 button {
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 15px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: #3762ea;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.UserCon {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 0 20px;
  margin-top: 20px;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px #00000012;
}

.UserHead {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  flex: 1;
  font-weight: 700;
  background-color: #fff;
}

.UserHead h4 {
  margin: 0;
  background-color: #e9ebec;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 5px;
  margin-left: 10px;
  border-radius: 5px;
}

.UserHead button {
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: #3762ea;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.UserHead1 {
  display: grid;
  grid-template-columns: 30% 37% 10% 10% 10%;
  align-items: center;
  min-height: 45px;
  flex: 1;
  background-color: #f7f8f8;
  padding: 0 5px;
}

.UserHead1 h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
}

.UserCon1 {
  display: grid;
  grid-template-columns: 30% 37% 10% 10% 10%;
  align-items: center;
  padding: 10px 0;
  flex: 1;
  cursor: pointer;
  border-top: 1px solid #dde1ef;
}

.UserConImg {
  display: flex;
  align-items: center;
}

.UserCon1 img {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  object-fit: cover;
  margin-left: 15px;
}

.UserCon1 h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}

.UserCon1 h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
}

.UserCon1 h4 {
  align-items: center;
  width: fit-content;
  margin: 0;
  margin-left: 8px;
  color: #2dcb72;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #e0f7ea;
  position: relative;
}

.UserDivRightProfile {
  display: flex;
  align-items: center;
  margin: 0 15px;
  margin-top: 15px;
}

.UserDivRightProfile img {
  height: 70px;
  width: 70px;
  border-radius: 10px;
  margin-right: 10px;
}

.UserDivRightProfile h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.UserDivRightProfile h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #3762ea;
}

.UserModal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  left: 0px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.2s ease;
}

.LogLoading{
  display: flex;
  margin-top: 15px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}

.EmailModalCon {
  height: 90vh;
  height: auto;
  width: auto;
  min-width: 500px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  /* overflow: auto; */
}

.DeleteModalCon {
  height: auto;
  width: auto;
  min-width: 320px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}

.DeleteModalCon h3 {
  margin: 0 15px;
  margin-top: 15px;
  font-size: 17px;
  font-weight: 500;
}

.DeleteModalCon p {
  margin: 0 15px;
  margin-top: 5px;
}

.DeleteBtnCon{
  display: flex;
  justify-content: flex-end;
  margin: 0 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.DeleteBtn1 {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 500;
  background-color: #e1e1e1;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
}

.DeleteBtn2 {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 500;
  background-color: #ea3737;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.UserModalOpen {
  visibility: visible;
  opacity: 1;
}

.UserModalHead {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #e4e6ed;
  padding: 0 15px;
  font-weight: 600;
  position: sticky;
  top: 0px;
}

.UserModalHead input{
  width: 200px;
  padding: 7px 10px;
  font-size: 14px;
  border: #eef0f7 1px solid;
  outline: none;
  border-radius: 5px;
  margin-right: 10px;
}

.UserModalHead button {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: #3762ea;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-right: 40px;
}

.UserModalLoad {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 15px;
  padding: 15px 0;
}

.UserModalCon {
  max-height: 70vh;
  height: auto;
  width: auto;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  overflow: auto;
  padding-bottom: 15px;
}

.UserModalCon::-webkit-scrollbar{
    display: none;
}

.UserModalCon3 {
  height: auto;
  width: 350px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  padding-bottom: 15px;
}

.UserModalCon2 {
  max-height: 500px;
  min-width: 700px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  overflow: auto;
  padding-bottom: 15px;
}

.UserModalClose {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.UserModalCon button {
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: #3762ea;
  border-radius: 5px;
  color: #fff;
  margin-right: 15px;
  cursor: pointer;
}

.UserModalCon2 button {
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: #3762ea;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.UserModalCon1 {
  display: flex;
  align-items: center;
  /* margin: 0 15px; */
  margin-left: 15px;
  margin-top: 15px;
}

.UserModalCon1 textarea {
  width: 375px;
  margin-top: 5px;
  padding: 10px;
  font-size: 15px;
  border: 1px #dde1ef solid;
  outline: none;
  border-radius: 5px;
  resize: none;
}

.UserModalCon4 {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-top: 15px;
}

.UserModalCon4 p {
  margin: 0;
  font-size: 15px;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
}

.UserModalCon h2 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.UserModalCon input {
  width: 170px;
  margin-top: 5px;
  padding: 10px;
  font-size: 15px;
  border: 1px #dde1ef solid;
  outline: none;
  border-radius: 5px;
}

.UserModalCon4 input {
  margin: 0;
  width: fit-content;
  margin-right: 6px;
}

.UserModalCon select {
  width: 190px;
  margin: 0 1px;
  margin-top: 5px;
  padding: 10px;
  font-size: 15px;
  outline: 1px #dde1ef solid;
  border: none;
  border-right: 10px #fff solid;
  border-radius: 5px;
}

.UserModalLoading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.UserModalInput {
  margin: 0 15px;
}

.UserModalCon table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 5px;
}

.UserModalCon td {
  text-align: left;
  padding: 10px 15px;
  font-weight: 400;
  cursor: pointer;
}

.UserModalCon th {
  text-align: left;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 15px;
}

.UserModalCon tr:nth-child(even) {
  border-bottom: 1px solid #00000012;
  border-top: 1px solid #00000012;
}

.UserModalCon tr td:nth-child(1) {
  width: 85%;
  font-size: 15px;
}

.UserModalSelectCon{
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px;
  margin-top: 15px;
  gap: 0 10px;
}

.UserModalSelect{
  width: fit-content;
  background-color: #f7f8f8;
  margin-top: 10px;
  font-size: 15px;
  border-radius: 4px;
  padding: 0 7.5px;
  cursor: pointer;
  user-select: none;
}

.UserModalBtnCon{
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #e4e6ed;
  position: sticky;
  bottom: -1px;
  left: 0px;
}

.UserModalBtnCon p{
  margin: 0;
  font-size: 14px;
  margin-right: 20px;
}

.UserModalBtnCon select{
  height: 35px;
  width: 100px;
  margin: 0;
  margin-left: 12.5px;
  font-size: 13px;
}

.UserModalBtnCon button{
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: #3762ea;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.UserModalBtn{
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 15px;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  background-color: #000;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}

.NationHead1 {
  display: grid;
  grid-template-columns: 20% 13.3% 20% 13.3% 20% 13.3%;
  align-items: center;
  flex: 1;
  background-color: #f7f8f8;
  padding: 10px 5px;
}

.NationHead1 h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
}

.NationHead2 {
  display: grid;
  grid-template-columns: 25% 25% 12.5% 25% 12.5%;
  align-items: center;
  flex: 1;
  background-color: #f7f8f8;
  padding: 10px 5px;
}

.NationHead2 h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
}

.NationCon1 {
  display: grid;
  grid-template-columns: 20% 13.3% 20% 13.3% 20% 13.3%;
  align-items: center;
  padding: 10px 5px;
  flex: 1;
  cursor: pointer;
  border-top: 1px solid #dde1ef;
}

.NationCon2 {
  display: grid;
  grid-template-columns: 25% 25% 12.5% 25% 12.5%;
  align-items: center;
  padding: 10px 5px;
  flex: 1;
  cursor: pointer;
  border-top: 1px solid #dde1ef;
}

.NationDivLeft {
  width: 70%;
}

.NationCon1 img {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  object-fit: cover;
  margin-left: 15px;
}

.NationCon1 h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  margin-left: 15px;
}

.NationCon2 h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
}

.NationCon1 h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
}

.NationCon2 h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.NationCon2 select {
  margin: 0 1px;
  margin-top: 5px;
  padding: 7px;
  font-size: 14px;
  outline: 1px #dde1ef solid;
  border: none;
  border-right: 10px #fff solid;
  border-radius: 5px;
}

.NationCon1 h4 {
  align-items: center;
  width: fit-content;
  margin: 0;
  margin-left: 10px;
  color: #2dcb72;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #e0f7ea;
  position: relative;
}

.NationCon1 h5 {
  align-items: center;
  width: fit-content;
  margin: 0;
  margin-left: 10px;
  color: #ff6c6c;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #ffe9e9;
  position: relative;
}

.NationDivRightDiv {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: 0 20px;
  margin-top: 10px;
}

.NationDivRightDiv h4 {
  width: 100px;
}

.Login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login form {
  height: auto;
  width: 350px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

.LoginHead {
  width: 100%;
  padding: 8px 0;
  background-color: #000d47;
}

.Login h2 {
  margin: 0 20px;
  margin-top: 20px;
}

.Login h3 {
  margin: 0 20px;
  margin-top: 7px;
  font-size: 17px;
  font-weight: 500;
}

.Login p {
  margin: 0 20px;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 500;
}

.Login input {
  height: 33px;
  outline: none;
  border: 1px solid #dfdfff;
  border-radius: 8px;
  margin: 0 20px;
  padding: 0 10px;
  margin-top: 7px;
  font-size: 15px;
  background-color: #fff;
}

.Login button {
  height: 35px;
  outline: none;
  border: none;
  border-radius: 8px;
  margin: 0 20px;
  padding: 0 10px;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  background-color: #181a1f;
}

.Template {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.Template_Table{
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: 20px;
  background-color: #fff;
}

.Template_Table th{
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  padding: 15px;
  color: #fff;
  background-color: #000;
}

.Template_Table td{
  text-align: left;
  font-size: 14px;
  padding: 15px;
}

.Template_Table tr{
  border-bottom: 1px solid #00000012;
}

.Template_Head {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.Template_Head select{
  background-color: #fff;
  border: none;
  border-right: 5px solid #fff;
  outline: 1px solid #b0b0b0;
  padding: 5px;
  border-radius: 5px;
}

.Template_Head_Icon {
  cursor: pointer;
  margin-left: 10px;
}

.Template h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.Template button {
  border: none;
  outline: none;
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.Template_Con {
  display: flex;
  flex-direction: column;
  width: fit-content;
  /* display: grid;
    grid-template-columns: 30% 30% 30%; */
  justify-content: space-between;
  margin: 0 25px;
  margin-top: 25px;
  font-weight: 400;
  padding-bottom: 50px;
}

.Template_Con p {
  font-weight: 500 !important;
}

.Template_Input_Con {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  margin-top: 10px;
}

.Template_Input_Con h2 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.Template_Input_Con input {
  width: 400px;
}

.Template_Input_Con textarea {
  height: 200px;
  width: 400px;
  margin-top: 5px;
  padding: 10px;
  font-size: 15px;
  border: 1px #dde1ef solid;
  outline: none;
  border-radius: 5px;
  resize: none;
}
